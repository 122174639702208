<template>
  <v-container fluid>
    <vivaWantingListByCenter />
  </v-container>
</template>

<script>
import vivaWantingListByCenter from "@/components/practicalVivaExam/vivaWantingList/vivaWantingListByCenter.vue";
export default {
  components: {
    vivaWantingListByCenter
  },
  data() {
    return {};
  },
  created() {},
  computed: {},
  methods: {},
  watch: {}
};
</script>
<style scoped></style>
